import { getEnv } from 'common-nextjs';

export function getBaseUrl(
  where?:
    | 'secureVpc'
    | 'iam'
    | 'inventory'
    | 'commerce'
    | 'rails'
    | 'bff'
    | 'edge',
) {
  if (getEnv('API_PROXY') === '1') {
    if (typeof document !== 'undefined') {
      return '/api/proxy';
    }
  }

  switch (where) {
    case 'iam':
      return getEnv('API_IAM_URL');
    case 'inventory':
      return getEnv('API_INVENTORY_URL');
    case 'commerce':
      return getEnv('API_COMMERCE_URL');
    case 'rails':
      return getEnv('API_RAILS_URL');
    case 'secureVpc':
      return getEnv('API_SECURE_URL');
    case 'bff':
      return getEnv('API_BFF_URL');
    case 'edge':
      return getEnv('API_EDGE_URL');
  }

  return getEnv('API_RAILS_URL');
}
