import { GetServerSidePropsContext, NextPageContext } from 'next';
import { qsStringify } from 'utils';
import {
  Session,
  createSession,
  destroySession,
  getSession,
  isSession,
} from '../Session';
import { ApiError } from '../api/ApiError'; // don't replace this import with ../api
import { DecodedJwt } from '../decodeJwt';
import { getBaseUrl } from './getBaseUrl';

export interface FetcherOptions {
  abortController?: AbortController;
  authHeader?: boolean;
  accessToken?: string;
  baseUrl?: string;
  dataOnly?: false;
  blob?: boolean;
  blobResponse?: boolean;
  method?: 'get' | 'post' | 'put' | 'delete';
  body?: any;
  ignoreErrors?: boolean;
  headers?: Record<string, any>;
  params?: Record<string, any>;
  skipRefresh?: boolean;
}

export type FetcherEndpoint =
  | ((jwt: Partial<DecodedJwt>) => string | false)
  | string;

export type FetcherArgs = [
  endpoint: FetcherEndpoint,
  config?: FetcherOptions | null,
];

export async function fetcher<R>(
  this: void | NextPageContext | GetServerSidePropsContext | Session,
  endpoint: FetcherEndpoint,
  config?: FetcherOptions | null,
): Promise<R> {
  const options = {
    authHeader: true,
    dataOnly: true,
    baseUrl: getBaseUrl(),
    method: 'get',
    ...config,
  };

  if (!options.baseUrl) {
    options.baseUrl = getBaseUrl();
  }

  const isThisASession = isSession(this);

  const session = isThisASession
    ? (this as Session)
    : await (!options.authHeader || options.skipRefresh
        ? createSession(
            this as NextPageContext | GetServerSidePropsContext | void,
          )
        : getSession(
            this as NextPageContext | GetServerSidePropsContext | void,
          ));

  const ctx = isThisASession
    ? (this as Session).__ctx
    : (this as NextPageContext | GetServerSidePropsContext);
  const req = ctx?.req;

  const accessToken = options.accessToken || session.accessToken;

  const evaluatedEndpoint =
    typeof endpoint === 'function'
      ? endpoint(session.decodedAccessToken || {})
      : endpoint;

  // If the endpoint function returned "false", bail out of fetching.
  // This is a way to stop fetching if you're logged out
  if (!evaluatedEndpoint) {
    console.warn('Fetcher endpoint function returned false');
    return null as unknown as R;
  }

  const headers = new Headers(options.headers);
  headers.set('x-sls-client-type', 'web');

  if (options.blob) {
    headers.delete('content-type');
  } else if (options.method !== 'get') {
    headers.set('content-type', 'application/json');
  }

  if (options.authHeader != false && accessToken) {
    headers.set('authorization', `Bearer ${accessToken}`);
  }

  if (req && req.connection?.remoteAddress) {
    const newForwarded = String(req.headers['x-forwarded-for'] ?? '').split(
      ',',
    );
    newForwarded.push(req.connection.remoteAddress);
    headers.set('x-forwarded-for', newForwarded.join(','));
  }

  if (session.clientId) {
    headers.set('x-session-id', session.clientId);
  }

  const stringifiedParams = qsStringify(options.params);
  const url = options.baseUrl + evaluatedEndpoint + stringifiedParams;
  const params: RequestInit = {
    headers,
    body:
      options.body && headers.get('content-type') === 'application/json'
        ? JSON.stringify(options.body)
        : options.body,
    credentials: 'same-origin',
    // mode: 'cors',
    method: options.method,
  };

  if (options.abortController?.signal) {
    params.signal = options.abortController.signal;
  }

  const response = await fetch(url, params);

  if (response.ok) {
    if (options.blobResponse) {
      // @ts-ignore
      return response.blob();
    }
    const content = await response.text();
    if (content) {
      const body = JSON.parse(content);
      if (options.dataOnly && body?.hasOwnProperty('data')) {
        return body?.data;
      }
      return body;
    }

    // @ts-ignore
    return null;
  }

  if (response.status === 401) {
    destroySession(session, ctx);
    console.log('unauthorized on ', url, params);
    throw new ApiError('Unauthorized');
  }

  if (options.ignoreErrors) {
    // @ts-ignore
    return null;
  } else {
    const content = await response.text();
    throw ApiError.fromResponse(response, content);
  }
}
